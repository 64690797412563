<template>
  <div>
    <vs-input icon-after="true" :disabled="disabled" :v-validate="vValidate" :data-vv-as="dataVvAs" :name="name" icon-pack="feather" icon="icon-search" readonly :label="label" class="w-full" v-model="nameBrowse" @click="browse"></vs-input>
    <input type="text" hidden :value="value">

    <vs-popup title="Find Commodity" :active.sync="isPopUp">
      <vs-table :sst="true" search @search="handleSearch" :data="data.data" @sort="handleSort" class="mb-3" >
        <template slot="thead">
          <vs-th sort-key="name">Name</vs-th>
        </template>
        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="tr.name">
              <!-- <vs-button @click="changeValue(tr)" type="line" size="small">Choose</vs-button> -->
              <a class="pointer" @click="changeValue(tr.id)">{{tr.name}}</a>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <vs-pagination :total="data.last_page" v-model="currentPage" class="mt-5"></vs-pagination>
    </vs-popup>
  </div>
</template>

<script>
import {mapState,mapActions} from 'vuex'
export default {
  props: ['value','label','vValidate','dataVvAs','name','disabled'],
  data(){
    return {
      nameBrowse: "",
      currentPage: 1,
      search:null,
      sortKey:null,
      sortVal:null,
      isPopUp:false
    }
  },
  watch:{
    async value(val) {
      if (val) {
        this.changeValue(val)
      } else {
        this.nameBrowse = ""
      }
    },
    search(){
      this.currentPage=1
      this.getData()
    },
    currentPage(){
      this.getData()
    }
  },
  computed:{
    ...mapState({
      data: state => state.commodities.rows
    })
  },
  created(){
    if(this.value) {
        this.changeValue(this.value)
    }
  },
  methods:{
    async browse(){
      await this.getData()
      this.isPopUp=true
    },
    async changeValue(value){
      let {data} = await this.dispatchShow(value)
      this.$emit('input', data.id)
      this.$emit('on-change', data)
      this.nameBrowse = `${data.name}`
      this.isPopUp=false
    },
    ...mapActions({
      dispatchIndex: 'commodities/index',
      dispatchShow: 'commodities/show'
    }),
    async getData(){
      let payload = {
        page: this.currentPage,
        search: this.search,
        sortKey: this.sortKey,
        sortVal: this.sortVal,
      }
      await this.dispatchIndex(payload)
    },
    handleSearch(val){
      this.search = val
    },
    handleSort(key,val){
      this.sortKey = key
      this.sortVal = val
      this.getData()
    }
  }
}
</script>

<style lang="scss">
  .pointer {
    cursor: pointer;
  }
</style>