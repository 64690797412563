<template>
  <div>
    <vs-input icon-after="true" :v-validate="vValidate" :data-vv-as="dataVvAs" :name="name" icon-pack="feather" icon="icon-search" readonly :label="label" class="w-full" v-model="nameBrowse" @click="browse"></vs-input>
    <input type="text" hidden :value="value">

    <vs-popup title="Find Job Order Commodities" fullscreen :active.sync="isPopUp">
      <vs-table :sst="true" search @search="handleSearch" :data="data.data" class="mb-3" >
        <template slot="thead">
          <vs-th sort-key="commodity_name">Goods / Commodity</vs-th>
          <vs-th sort-key="qty">Leftover</vs-th>
          <vs-th sort-key="job_order_code">No Job Order</vs-th>
          <vs-th sort-key="customer_name">Customer</vs-th>
        </template>
        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="tr.commodity_name">
              <a class="pointer" @click="changeValue(tr.id)">{{tr.commodity_name}}</a>
            </vs-td>
            <vs-td label="Leftover" class='text-right' :data="tr.leftover">{{tr.leftover}}</vs-td>
            <vs-td label="No Job Order" :data="tr.job_order_code">{{tr.job_order_code}}</vs-td>
            <vs-td label="Customer" :data="tr.customer_name">{{tr.customer_name}}</vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <vs-pagination :total="data.last_page" v-model="currentPage" class="mt-5"></vs-pagination>
    </vs-popup>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
export default {
  props: ['value','label','vValidate','dataVvAs','name','params', 'is_open'],
  data(){
    return {
      nameBrowse: "",
      currentPage: 1,
      search:null,
      sortKey:null,
      sortVal:null,
      isPopUp:false,
    }
  },
  watch:{
    async value(val) {
      if (val) {
        this.getValue(val)
      } else {
        this.nameBrowse = ""
      }
      this.$emit('input', val)
    },
    is_open(val){
        if(val == true) {
            this.browse()
        }
    },
    search(){
      this.currentPage=1
      this.getData()
    },
    // is_open(val){
    //     if(val == true) {
    //         this.browse()
    //     }
    // },
    currentPage(){
      this.getData()
    }
  },
  computed:{
    ...mapState({
      data: state => state.job_order_details.rows
    })
  },
  methods:{
    // handleSearch(val){
    //   this.search = val
    // },
    async browse(){
      await this.getData()
      this.isPopUp=true
    },
    changeValue(value){
      this.isPopUp=false
      this.getValue(value)
    },
    async getValue(value){
      let {data} = await this.dispatchShow(value)
      this.$emit('input', value)
      this.$emit('on-change', data)
    },
    ...mapActions({
      dispatchIndex: 'job_order_details/index',
      dispatchShow: 'job_order_details/show'
    }),
    async getData(){
      let payload = {
        page: this.currentPage,
        search: this.search,
        sortKey: this.sortKey,
        sortVal: this.sortVal,
      }
      await this.dispatchIndex(payload)
    },
    handleSearch(val){
      this.search = val
    },
    handleSort(key,val){
      this.sortKey = key
      this.sortVal = val
      this.getData()
    }
  }
}
</script>

<style lang="scss">
  .pointer {
    cursor: pointer;
  }
</style>