<template>
  <div>
    <vs-input icon-after="true" :v-validate="vValidate" :data-vv-as="dataVvAs" :name="name" icon-pack="feather" icon="icon-search" readonly :label="label" class="w-full" v-model="nameBrowse" @click="browse"></vs-input>
    <input type="text" hidden :value="value">

    <vs-popup title="Find Job Order Commodities" fullscreen :active.sync="isPopUp">
      <vs-table search :data="data" class="mb-3" >
        <template slot="thead">
          <vs-th sort-key="commodity">Goods / Commodity</vs-th>
          <vs-th sort-key="qty">Qty</vs-th>
          <vs-th sort-key="unit">Unit</vs-th>
          <vs-th sort-key="weight">Weight (Kg)</vs-th>
          <vs-th sort-key="volume">Volume (m<sup>3</sup>)</vs-th>
          <vs-th sort-key="imposition_name">Imposition</vs-th>
          <vs-th sort-key="price">Price</vs-th>
          <vs-th sort-key="total_price">Total Price</vs-th>
        </template>
        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="tr.commodity">
              <a class="pointer" @click="changeValue(tr.id)">{{tr.commodity}}</a>
            </vs-td>
            <vs-td label="Qty" :data="tr.qty">{{tr.qty}}</vs-td>
            <vs-td label="Unit" :data="tr.unit">{{tr.unit}}</vs-td>
            <vs-td label="Weight (Kg)" :data="tr.weight">{{tr.weight}}</vs-td>
            <vs-td label="Volume (m<sup>3</sup>)" :data="tr.volume">{{tr.volume}}</vs-td>
            <vs-td label="Imposition" :data="tr.imposition_name">{{tr.imposition_name}}</vs-td>
            <vs-td label="Price" :data="tr.price">{{tr.price | numFormat}}</vs-td>
            <vs-td label="Total Price" :data="tr.total_price">{{tr.total_price | numFormat}}</vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vs-popup>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
export default {
  props: ['value','label','vValidate','dataVvAs','name','params'],
  data(){
    return {
      nameBrowse: "",
      currentPage: 1,
      search:null,
      sortKey:null,
      sortVal:null,
      isPopUp:false,
      data: []
    }
  },
  watch:{
    async value(val) {
      if (val) {
        this.getValue(val)
      } else {
        this.nameBrowse = ""
      }
      this.$emit('input', val)
    },
    search(){
      this.currentPage=1
      this.getData()
    },
    currentPage(){
      this.getData()
    }
  },
  methods:{
    async browse(){
      if(!this.params.job_order_id) return this.$vs.notify({
        title: `Oops!`,
        text: `Job Order must be filled!`,
        color: `warning`
      });
      await this.getData()
      this.isPopUp=true
    },
    changeValue(value){
      this.$emit('input', value)
      this.isPopUp=false
    },
    async getValue(value){
      let {data} = await this.dispatchShow(value)
      this.$emit('on-change', data)
      this.nameBrowse = `${data.commodity}`
    },
    ...mapActions({
      dispatchIndex: 'job_orders/get_commodities',
      dispatchShow: 'job_orders/detail_show'
    }),
    async getData(){
      let { data } = await this.dispatchIndex(this.params.job_order_id)
      this.data = data
    },
    handleSearch(val){
      this.search = val
    },
    handleSort(key,val){
      this.sortKey = key
      this.sortVal = val
      this.getData()
    }
  }
}
</script>

<style lang="scss">
  .pointer {
    cursor: pointer;
  }
</style>